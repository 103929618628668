<template>
  <div class="contents allVod">
    <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("top-select-ai-live-data-ch") }}</h1>
    <div class="liveChannel">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="channelId">{{ $t("live-data-channel-id") }}</option>
            <option value="eventId">{{ $t("event-data-site-num") }}</option>
            <option value="server">{{ $t("live-data-channel-server") }}</option>
            <option value="eventTitle">{{ $t("event-data-site-name") }}</option>
            <option value="userName">{{ $t("live-data-channel-operator") }}</option>
            <option value="streamUrl">{{ $t("live-data-channel-stream-url") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search()"
          />
        </div>
      
        <div class="flexC">
          <p class="bold">{{ $t("data-operation-device-type") }}</p>
          <label
          ><input
              type="radio"
              name="device"
              v-model="deviceType"
              :value="null"
              checked
          />{{ $t("data-operation-device-type-all") }}</label
          >
          <label
          ><input
              type="radio"
              v-model="deviceType"
              name="device"
              value="Drone"
          />{{ $t("data-operation-device-type-drone") }}</label
          >
          <label
          ><input
              type="radio"
              v-model="deviceType"
              name="device"
              value="Mobile"
          />{{ $t("data-operation-device-type-phone") }}</label
          >
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 0px;">
          <button class="point medium" @click="search()" style="width: 120px; margin-left: 20px;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:300;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;border-color:#ddd;color:#969696; font-weight:300;border-radius:5px;">
            {{ $t("btn-reset") }}
          </button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }} <span class="live">{{ total }}</span
        >{{ $t("data-case-ea") }}
        </h2>
        <ul>
          <li style="font-size: 1.4rem;">
            - {{ $t("live-data-channel-status-ready") }}
            <span class="bold" v-if="4">{{ channelListCounts.waitCount }}{{ $t("data-default-ea") }}</span>
          </li>

          <li class="live" style="font-size: 1.4rem;">
            {{ $t("live-data-channel-status-live") }}
            <span class="bold" v-if="4">{{ channelListCounts.assignedCount }}{{ $t("data-default-ea") }}</span>
            ( {{ $t("live-data-channel-status-recoding") }}
            <span class="bold" v-if="4">{{ channelListCounts.recordCount }}{{ $t("data-default-ea") }}</span>
            )
          </li>
          <li class="error" style="font-size: 1.4rem;">
            {{ $t("live-data-channel-status-error") }}
            <span class="bold" v-if="4">{{ channelListCounts.errorCount }}{{ $t("data-default-ea") }}</span>
          </li>
        </ul>
        <button class="samll right refreshBtn" @click="reload">{{ $t("btn-refresh") }}</button>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
          </div> -->
        <table style="table-layout: fixed">
          <tr>
            <th>No</th>
            <th>{{ $t("live-data-channel-id") }}</th>
            <th>{{ $t("live-data-channel-server") }}</th>
            <th>{{ $t("live-data-channel-stream-url") }}</th>
            <th>Key</th>
            <th>{{ $t("event-data-site-name") }}</th>
            <th>{{ $t("event-data-site-num") }}</th>
            <th>{{ $t("data-operation-device-type") }}</th>
            <th>{{ $t("live-data-channel-operator") }}</th>
            <th>{{ $t("live-data-channel-status") }}</th>
          </tr>
          <tr v-if="total == 0">
            <td colspan="10" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in channelList" :key="i">
            <td>{{ total - (currentPage * size + i) }}</td>
            <td>
              <a @click="handleDetail(data.channelId)" class="link">
                {{ data.channelId }}
              </a>
            </td>
            <td>{{ data.streamServer.name }}</td>
            <td>{{ getLiveUrl(data) }}</td>
            <td>{{ getIsNullToString(data.streamKey) }}</td>
            <td>{{ getIsNullToString(data.eventTitle) }}</td>
            <td>{{ getIsNullToString(data.eventId) }}</td>
            <td>
              {{ $t(getDeviceTypeName(data.assignedDeviceType)) }}
            </td>
            <td>{{ getIsNullToString(data.assignedUserName) }}</td>
            <td>
              {{ $t(getChannelStatusName(data)) }}
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="15"
              :current-page="currentPage + 1"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title {display: none;}
.refreshBtn{border: 1px solid #0080ff;color: #0080ff;padding: 0 30px;border-radius: 5px;}
.refreshBtn:hover{border: 1px solid #0080ff;color: #0080ff;padding: 0 30px;border-radius: 5px;}
</style>
<script>
  // import sha256 from "js-sha256";
  import {format} from "@/mixins/format";
  import moment from "moment";
  import {fetchChannelList, fetchChannelListCounts ,getDeviceTypeName, getChannelStatusName } from "@/api/channel";
  
  export default {
    name: "AiChannelList",
    mixins: [format],
    data() {
      return {
        moment: moment,
        channelList: [],
        channelListCounts: {waitCount: 0, assignedCount: 0, recordCount: 0, errorCount: 0},
        error: false,
        total: 0,
        keyword: "",
        keywordType: "all",
        status: null,
        isRecord: false,
        all: "",
        deviceType: null,
        currentPage: 0,
        size: 10,
        eventId : null,
      };
    },
    created() {
      this.$store.dispatch("store/SET_NAVBAR", {nav: 8, sub: 0});
      this.eventId = this.$route.params.eventId
      this.preset();
    },
    mounted() {
      this.callFunctionsWhenMounted();
    },
    methods: {
      preset(){
        var localStorageData = localStorage.getItem("channel_searchData");
        if(localStorageData != undefined) {
          var searchData = JSON.parse(localStorageData);
  
          this.keyword = searchData.keyword;
          this.keywordType = searchData.keywordType;
          this.userTypes = searchData.userTypes;
          this.statuses = searchData.statuses;
          this.currentPage = searchData.pageNumber;
          this.deviceType = searchData.deviceTypes;
  
          if(searchData.recordStatus == "Record"){
            this.isRecord = true;
          }
  
          if(searchData.assignedStatus == null && searchData.findStatuses == null){
            this.status = null;
          }
  
          if(searchData.assignedStatus == "Assigned" && searchData.findStatuses == null){
            this.status = "Assigned";
          }
  
          if(searchData.assignedStatus == "Unassigned" && searchData.findStatuses == null){
            this.status = "Unassigned";
          }
  
          if(searchData.assignedStatus == null && searchData.findStatuses == "Error"){
            this.status = "Error";
          }
        }
      },
      persist(params){
        localStorage.setItem("channel_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
      },
      reload() {
        this.getChannelList();
      },
      handleDetail(id) {
        
        this.$router.push({name: "AiChannelDetail", query: {channelId: id}});
      },
      getIsNullToString(value) {
        var result = '-'
        if (value != null) {
          result = value
        }
        return result
      },
      getLiveUrl(channelData) {
        if (channelData.streamServer == null) {
          return "-"
        }
        var properties = channelData.streamServer.properties
        return properties.liveUrl + "/" + properties.application + "/" + channelData.streamKey + "/" + properties.livePostfix
      },
      getDeviceTypeName(type){
        return getDeviceTypeName(type);
      },
      getChannelStatusName(data){
        return getChannelStatusName(data);
      },
      search() {
        this.currentPage = 0;
        this.getChannelList();
      },
      getChannelList() {
        let params = {
          pageNumber: this.currentPage,
          pageSize: this.size,
          keywordType: this.keywordType,
          keyword: this.keyword,
          findStatuses: null,
          recordStatus: null,
          deviceTypes: this.deviceType,
          assignedStatus: "Assigned",
          isAi: true,
          eventId: this.eventId,
        };
        switch (this.status) {
          default:
          case null :
            params.assignedStatus = null;
            params.findStatuses = null;
            break;
          case "Assigned":
            params.assignedStatus = "Assigned";
            params.findStatuses = null;
            break;
          case "Unassigned":
            params.assignedStatus = "Unassigned";
            params.findStatuses = null;
            break;
          case "Error":
            params.assignedStatus = null;
            params.findStatuses = "Error";
            break;
        }
  
        if (this.isRecord) params.recordStatus = "Record"; else params.recordStatus = "";
  
        this.persist(params);
        fetchChannelList(params).then((res) => {
          if (res.data.result == 0) {
            this.total = res.data.data.total;
            this.channelList = res.data.data.content;
            console.log("aiChannelList" , this.channelList)
          } else {
            alert(res.data.message);
          }
        });
  
        fetchChannelListCounts(params).then((res) => {
          if (res.data.result == 0) {
            this.channelListCounts = res.data.data
  
          } else {
            alert(res.data.message)
          }
        });
      },
      inNumber() {
        if (
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            event.keyCode == 8 ||
            event.keyCode == 37 ||
            event.keyCode == 39 ||
            event.keyCode == 46
        ) {
          return;
        } else {
          return false;
        }
      },
      handleCurrentChange(val) {
        this.currentPage = val - 1;
        this.getChannelList();
      },
      handleCancel() {
        this.$router.push("/dashboard/overall");
      },
      handleRefresh() {
        this.reset();
        this.callFunctionsWhenMounted();
      },
      reset(){
        this.currentPage = 0;
        this.keywordType = "all";
        this.keyword = "";
        this.deviceType = null;
        this.status = null;
        this.isRecord = false;
      },
      callFunctionsWhenMounted(){
        this.getChannelList();
      },
    },
  };
</script>